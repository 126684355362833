<template>
  <v-card>
    <v-toolbar color="primary" dark>
      <v-toolbar-title>DETAIL DES BASES</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="closeBasesDetails">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-list >
      <v-list-item-group >
        <v-list-item class="disable-interaction" v-for="item in baseToTcheckId ? sortByBaseToTcheck : basesDetails" :key="item._id">
          <v-list-item-content >
            <v-list-item-title class="headline text-center mb-4"
            >{{ item.client && item.client.join(', ') }}
            <v-divider class="mt-4"></v-divider>
            </v-list-item-title>
            <v-row>
              <v-col cols="6">
                <v-list-item-subtitle>Nom de la base : 
                  <span class="body-2">{{ item.baseName }}</span>
                </v-list-item-subtitle>
                <v-list-item-subtitle>URL: 
                  <span class="body-2">{{ item.requestedURLs }}</span>
                </v-list-item-subtitle>
                <v-list-item-subtitle>Date de la requête : 
                  <span class="body-2">{{ formatDate(item.requestDate) }}</span>
                </v-list-item-subtitle>
                <v-list-item-subtitle>Validation de la direction : 
                  <span class="body-2">{{ item.validationDirectionDate ? formatDate(item.validationDirectionDate) : 'Non validée' }}</span>
                </v-list-item-subtitle>
                <v-list-item-subtitle>Commentaire de la direction : 
                  <span class="body-2">{{ item.validationDirectionRemarks && item.validationDirectionRemarks ? item.validationDirectionRemarks : 'Non renseigné' }}</span>
                </v-list-item-subtitle>
                <v-list-item-subtitle>Validation du sysadmin : 
                  <span class="body-2">{{ item.sysAdminValidationDate ? formatDate(item.sysAdminValidationDate) : 'Non validée' }}</span>
                </v-list-item-subtitle>
                <v-list-item-subtitle>Date de test de la base : 
                  <span class="body-2">{{ item.testedDate ? formatDate(item.testedDate) : 'Non testée' }}</span>
                </v-list-item-subtitle>
                <v-list-item-subtitle>Date d'ouverture vers l'extérieur : 
                  <span class="body-2">{{ item.isOpenedToOutside ? formatDate(item.openedToOutsideDate) : 'Non validée' }}</span>
                </v-list-item-subtitle>
                <v-list-item-subtitle>Source : 
                  <span class="body-2">{{ item.sourceDatabases && item.sourceDatabases !== 'null' ? item.sourceDatabases : 'Non renseigné' }}</span>
                </v-list-item-subtitle>
                <v-list-item-subtitle>Usage : 
                  <span class="body-2">{{ item.usage && item.usage !== 'null' ? item.usage : 'Non renseigné' }}</span>
                </v-list-item-subtitle>
                <v-list-item-subtitle>Nature : 
                  <span class="body-2">{{ item.databaseNature ? item.databaseNature : 'Non renseigné' }}</span>
                </v-list-item-subtitle>
                <v-list-item-subtitle>Type : 
                  <span class="body-2">{{ item.databaseType }}</span>
                </v-list-item-subtitle>
                <v-list-item-subtitle>Type de requête : 
                  <span class="body-2">{{ item.requestType && item.requestType !== 'null' ? item.requestType : 'Non renseigné' }}</span>
                </v-list-item-subtitle>
                <v-list-item-subtitle>Time Zone : 
                  <span class="body-2">{{ item.timezone }}</span>
                </v-list-item-subtitle>
                <v-list-item-subtitle>TOPS : 
                  <span class="body-2">{{ item.tops && item.tops.join(', ') }}</span>
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  Date d'archivage : 
                  <span class="body-2">{{ item.archiveDate && item.archiveDate ? formatDate(item.archiveDate) : 'Non renseigné' }}</span>
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  Raison de l'archivage : 
                  <span class="body-2">{{ item.archiveReason && item.archiveReason ? item.archiveReason : 'Non renseigné' }}</span>
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  Date de désarchivage : 
                  <span class="body-2">{{ item.unarchiveDate && item.unarchiveDate ? formatDate(item.unarchiveDate) : 'Non renseigné' }}</span>
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  Raison du désarchivage : 
                  <span class="body-2">{{ item.unarchiveReason && item.unarchiveReason ? item.unarchiveReason : 'Non renseigné' }}</span>
                </v-list-item-subtitle>
              </v-col>
              <v-col cols="1">
                <v-divider vertical></v-divider>
              </v-col>
              <v-col cols="5">
                <v-list-item-subtitle>
                  HTTPS :
                  <v-icon :color="item.isHTTPS ? 'green' : 'red'">{{ item.isHTTPS ? 'mdi-check-bold' : 'mdi-close-thick' }}</v-icon>
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  Validée :
                  <v-icon :color="item.isValidateBase ? 'green' : 'red'">{{ item.isValidateBase ? 'mdi-check-bold' : 'mdi-close-thick' }}</v-icon>
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  Employés :
                  <v-icon :color="item.employees ? 'green' : 'red'">{{ item.employees ? 'mdi-check-bold' : 'mdi-close-thick' }}</v-icon>
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  Activation globale :
                  <v-icon :color="item.globalActivation ? 'green' : 'red'">{{ item.globalActivation ? 'mdi-check-bold' : 'mdi-close-thick' }}</v-icon>
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  GTA :
                  <v-icon :color="item.gta ? 'green' : 'red'">{{ item.gta ? 'mdi-check-bold' : 'mdi-close-thick' }}</v-icon>
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  Active :
                  <v-icon :color="item.isActive ? 'green' : 'red'">{{ item.isActive ? 'mdi-check-bold' : 'mdi-close-thick' }}</v-icon>
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  Signatures :
                  <v-icon :color="item.signatures ? 'green' : 'red'">{{ item.signatures ? 'mdi-check-bold' : 'mdi-close-thick' }}</v-icon>
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  Espace de stockage :
                  <v-icon :color="item.storageSpace ? 'green' : 'red'">{{ item.storageSpace ? 'mdi-check-bold' : 'mdi-close-thick' }}</v-icon>
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  URL SDC :
                  <v-icon :color="item.urlSdc ? 'green' : 'red'">{{ item.urlSdc ? 'mdi-check-bold' : 'mdi-close-thick' }}</v-icon>
                </v-list-item-subtitle>
                <v-list-item-subtitle>URL SDC : 
                  <span class="body-2">{{ item.sdcUrl && item.sdcUrl ? item.sdcUrl : 'Non renseigné' }}</span>
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  URL SDP :
                  <v-icon :color="item.urlSdp ? 'green' : 'red'">{{ item.urlSdp ? 'mdi-check-bold' : 'mdi-close-thick' }}</v-icon>
                </v-list-item-subtitle>
                <v-list-item-subtitle>URL SDP : 
                  <span class="body-2">{{ item.sdpUrl && item.sdpUrl ? item.sdpUrl : 'Non renseigné' }}</span>
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  COPY HR :
                  <v-icon :color="item.copyHr ? 'green' : 'red'">{{ item.copyHr ? 'mdi-check-bold' : 'mdi-close-thick' }}</v-icon>
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  COPY SDC :
                  <v-icon :color="item.copySdc ? 'green' : 'red'">{{ item.copySdc ? 'mdi-check-bold' : 'mdi-close-thick' }}</v-icon>
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  COPY SDP :
                  <v-icon :color="item.copySdp ? 'green' : 'red'">{{ item.copySdp ? 'mdi-check-bold' : 'mdi-close-thick' }}</v-icon>
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  COPY USER :
                  <v-icon :color="item.copyUser ? 'green' : 'red'">{{ item.copyUser ? 'mdi-check-bold' : 'mdi-close-thick' }}</v-icon>
                </v-list-item-subtitle>
              </v-col>
            </v-row>
            <!-- afficher le contact de la base -->
            <v-row>
              <v-list-item-title class="text-center headline">
                <v-divider class="mb-4"></v-divider>
                Contact de la base :
                <v-divider class="mt-4"></v-divider>
              </v-list-item-title>
              <v-col cols="6" class="enable-interaction">
                <v-list-item-subtitle class="d-flex align-center py-2">Nom :  
                  <div style="width: 100%;">
                    <code-snippet
                      :code="item.contact && item.contact.lastName ? item.contact.lastName : 'Non renseigné'"
                      enable-copy
                    ></code-snippet>
                  </div>
                </v-list-item-subtitle>
                <v-list-item-subtitle class="d-flex align-center">Prénom : 
                  <div style="width: 100%;">
                    <code-snippet
                      :code="item.contact && item.contact.firstName ? item.contact.firstName : 'Non renseigné'"
                      enable-copy
                    ></code-snippet>
                  </div>
                </v-list-item-subtitle>
              </v-col>
              <v-col cols="1">
                <v-divider vertical></v-divider>
              </v-col>
              <v-col cols="5" class="enable-interaction">
                <v-list-item-subtitle class="d-flex align-center py-2">Email : 
                  <div style="width: 100%;">
                    <code-snippet
                      :code="item.contact && item.contact.email ? item.contact.email : 'Non renseigné'"
                      enable-copy
                    ></code-snippet>
                  </div>
                </v-list-item-subtitle>
                <v-list-item-subtitle class="d-flex align-center">Téléphone : 
                  <div style="width: 100%;">
                    <code-snippet
                      :code="item.contact && item.contact.phone ? item.contact.phone : 'Non renseigné'"
                      enable-copy
                    ></code-snippet>
                  </div>
                </v-list-item-subtitle>
              </v-col>
            </v-row>
          </v-list-item-content> 
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
import moment from 'moment';
import { mapMutations, mapState } from 'vuex';
import CodeSnippet from '@/Components/Commons/UiElements/CodeSnippet.vue';

export default {
  name: 'basesUpdate',
  components: {
    CodeSnippet,
  },
  data: () => ({
    search: '',
    basesDetails: [],
    bases: {},
  }),
  computed: {
    ...mapState(['clientList', 'baseList', 'baseToTcheckId']),
    isBaseToTcheck() {
      return this.baseList.data.some(base => base._id === this.baseToTcheckId);
    },
    sortByBaseToTcheck() {
      return this.baseList.data.filter(base => base._id === this.baseToTcheckId);
    },
  },
  filters: {
    formatDate(date) {
      if (date !== null && date !== undefined) {
        return moment(date).format('DD/MM/YYYY');
      }
    },
  },
  methods: {
    ...mapMutations(['setBaseToTcheckId']),
    closeBasesDetails() {
      this.$emit('close-modal');
    },
    // Ajoutez d'autres méthodes si nécessaire
  },
  async mounted() {
    this.bases = this.baseList.data; 
  },
};
</script>

<style scoped>
.v-list-item__subtitle {
  font-weight: bold;
}
.col-1 {
  max-width : 1px;
}
.disable-interaction {
    pointer-events: none;
    user-select: text; /* permettre la sélection du texte */
    -moz-user-select: text; 
    -webkit-user-select: text;
    -ms-user-select: text; 
  }
.enable-interaction {
    pointer-events: auto;
    user-select: text; /* permettre la sélection du texte */
    -moz-user-select: text; 
    -webkit-user-select: text;
    -ms-user-select: text; 
  }
</style>
