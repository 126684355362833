import Api from "@/Services/Api";

export default {
    async getClientList() {
        let response = await Api().get("/support-vision/client")
        return response.data
    },
    async getClientById(id) {
      let response = await Api().get(`/support-vision/client/${id}`)
        return response.data
    },
    async createClient(data) {
        let response = await Api().post(`/support-vision/client/`, {client: data})
        return response.data
    },
    async updateClient(id, data) {
        let response = await Api().put(`/support-vision/client/${id}`, {client: data})
        return response.data
    },
    async removeClient(id) {
        let response = await Api().delete(`/support-vision/client/${id}`)
        return response
    }
}