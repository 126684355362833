<template>
  <v-card>
    <v-toolbar 
      :color="base.validationStatus === 'Refus' ? 'red' : 'primary'"
      dark 
      class="mb-2"
    > 
      <v-toolbar-title v-if="base.validationStatus === 'Refus'" class="">Editer la base en tenant compte des remarques</v-toolbar-title>
      <v-toolbar-title v-else>Editer la base</v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- voir le contact recu dans base.contact -->
      <v-btn icon @click="contactDialog = true">
        <v-icon>mdi-account</v-icon>
      </v-btn>
      <!-- dialog -->
      <v-dialog v-model="contactDialog" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Editer le contact</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" >
                  <v-text-field v-model="base.contact.function" label="Fonction"></v-text-field>
                </v-col>
                <v-col cols="6" >
                  <v-text-field v-model="base.contact.firstName" label="Prénom"></v-text-field>
                </v-col>
                <v-col cols="6" >
                  <v-text-field v-model="base.contact.lastName" label="Nom"></v-text-field>
                </v-col>
                <v-col cols="6"  >
                  <v-text-field v-model="base.contact.email" label="Email"></v-text-field>
                </v-col>
                <v-col cols="6"  >
                  <v-text-field v-model="base.contact.phone" label="Téléphone"></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="contactDialog = false"
            >Annuler</v-btn>
            <v-btn color="blue darken-1" text @click="addContact">Editer</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-btn icon @click="closeUpdateBasesModal">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text style="max-height: 70vh">
        <div>
          <span class="subtitle-2">ID BASE</span>
          <br />
          <code-snippet :code="base._id" enable-copy> </code-snippet>
        </div>
      </v-card-text>
    <v-progress-circular v-if="loader" class="loader" :size="70" :width="10" color="purple"
      indeterminate></v-progress-circular>
    <v-form @submit.prevent="onSubmit">
      <div class="d-flex mx-2 form-container">
        <div style="width: 48%;">
          <v-card-text>
            <v-text-field label="Nom de la base" v-model="base.baseName"></v-text-field>
            <div class="d-flex form-container">
              <div style="width: 48%;">
                <v-menu>
                  <template v-slot:activator="{ on }">
                    <v-text-field :value="base.requestDate | formatDate" label="Date de la demande" v-on="on">
                      {{ base.requestDate }}
                    </v-text-field>
                  </template>
                  <v-date-picker style="width: 100%" v-model="dateWithTime" :locale="$i18n.locale"
                    color="primary"></v-date-picker>
                </v-menu>
                <v-autocomplete 
                  label="Client" 
                  v-model="base.client" 
                  clearable 
                  :items="clientOptions" 
                  item-text="name"
                  item-value="name"
                >
                </v-autocomplete>
                <template v-if="clientOptionsFiltered.length > 0">
                  <v-list>
                    <v-list-item v-for="(client, index) in clientOptionsFiltered" :key="index"
                      @click="selectClient(client)">
                      <v-list-item-title>{{ client.name }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </template>
                <v-select 
                  label="Type de demande" 
                  :items="requestTypeOptions"
                  :value="base.requestType"
                  clearable
                >
                </v-select>
              </div>
              <div style="width: 48%;">
                <v-text-field label="URLs demandé de la nouvelle base" v-model="base.requestedURLs" :rules="[requestedURLsRule]"></v-text-field>
                <v-select label="Nature de la base" clearable v-model="base.databaseNature"
                  :items="databaseNatureOptions"></v-select>
                <v-autocomplete 
                  label="Fuseau horaire" 
                  v-model="base.timezone" 
                  :items="timezones" 
                  clearable
                  ></v-autocomplete>
              </div>
            </div>
            <v-row>
              <v-col cols="5"> 
                <v-select label="TOPS" v-model="base.tops" multiple clearable :items="topsOptions"></v-select>
              </v-col>
              <v-col cols="3">
              <v-checkbox v-if="base.databaseNature === 'Globale'" label="Global Activation"  v-model="base.globalActivation"></v-checkbox>
              </v-col>
              <v-col cols="2"> 
                <v-checkbox label="HTTPS" v-model="base.isHTTPS"></v-checkbox>
              </v-col>
              <v-col cols="2">
              <v-checkbox label="GTA" v-model="base.gta"></v-checkbox> 
              </v-col>
            </v-row>
            <div class="d-flex justify-space-between"
            style="gap: 1rem;"
            >
              <v-select label="Usage" v-model="base.usage" clearable :items="usageOptions"></v-select>
              <v-select label="Type de base" v-model="base.databaseType" clearable :items="databaseTypeOptions"></v-select>
            </div>
            <v-textarea label="Remarques" rows="3" v-model="base.remarks"></v-textarea>
          </v-card-text>
        </div>
        <v-divider vertical></v-divider>
        <div style="width: 48%;">
          <v-card-text>
            <v-checkbox label="URL SDP" v-model="base.urlSdp"></v-checkbox>
            <v-text-field v-if="base.urlSdp" label="Saisir l'URL SDP" v-model="base.sdpUrl"></v-text-field>
            <v-checkbox label="URL SDC" v-model="base.urlSdc"></v-checkbox>
            <v-text-field v-if="base.urlSdc" label="Saisir l'URL SDC" v-model="base.sdcUrl"></v-text-field>
          </v-card-text>
          <v-card-text>
            <v-autocomplete 
              label="Code source" 
              v-model="base.sourceCode" 
              :items="sourceDatabaseOptions"
              item-text="name" 
              clearable
            ></v-autocomplete>
            <template v-if="base.sourceCode && base.sourceCode.length > 0 && base.sourceCode !== 'modele2.planett.net'">
              <v-textarea label="Remarques Code Source" rows="3" v-model="base.sourceCodeRemarks"></v-textarea>
            </template>
            <v-autocomplete 
              label="Base de données source" 
              v-model="base.sourceDatabase" 
              :items="sourceDatabaseOptions"
              item-text="name" 
              clearable
            ></v-autocomplete>
            <template v-if="sourceDatabaseOptionsFiltered.length > 0">
              <v-list>
                <v-list-item v-for="(database, index) in sourceDatabaseOptionsFiltered" :key="index"
                  @click="selectSourceDatabase(database)">
                  <v-list-item-title>{{ database.name }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </template>
            <div class="d-flex justify-space-between align-center mt-4 mb-4">
              <v-checkbox label="Toutes les données" v-model="allData" @change="toggleAllData"></v-checkbox>
              <v-checkbox label="Utilisateur" v-model="base.copyUser" :disabled="allData"></v-checkbox>
              <v-checkbox label="Paramètres RH/HR & Absences" v-model="base.copyHr" :disabled="allData"></v-checkbox>
              <v-checkbox label="SDP" v-model="base.copySdp" :disabled="allData"></v-checkbox>
              <v-checkbox label="SDC/Customer" v-model="base.copySdc" :disabled="allData"></v-checkbox>
              <!-- planning -->
              <v-checkbox label="Planning" v-model="base.copyPlanning" :disabled="allData"></v-checkbox>
            </div>
            <!-- <v-file-input
              label="Logo" 
              @change="handleLogoChange" 
              :value="logo" 
              accept="image/*"
              type="file"
            >
            </v-file-input> -->
            <v-text-field label="Logo" v-model="base.logo"></v-text-field>
            <v-img v-if="base.logo" 
              :src="base.logo" 
              contain 
              width="100%"
              height="100"
            >
            </v-img>
          </v-card-text>
          <v-card-text>
            <div class="d-flex justify-space-between">
              <v-checkbox label="Espace de stockage" v-model="base.storageSpace"></v-checkbox>
              <v-checkbox label="Signatures" v-model="base.signatures"></v-checkbox>
              <v-checkbox label="Effectifs" v-model="base.employees"></v-checkbox>
            </div>
          </v-card-text>
        </div>
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue-darken-1" variant="text" @click="closeUpdateBasesModal">
          Annuler
        </v-btn>
        <v-btn color="warning" @click="saveAndCloseModal" :disabled="!isFormValid">Enregistrer</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>

import CodeSnippet from '@/Components/Commons/UiElements/CodeSnippet.vue';
import Images from '@/Utils/Images';
import moment from 'moment';
import momentTimeZones from 'moment-timezone';
import { mapState, mapMutations } from 'vuex';
import BasesService from '../../../../Services/SupportVision/BasesService';
import { VAutocomplete } from 'vuetify/lib';

export default {
  name: 'basesUpdate',
  components: {
    CodeSnippet,
    VAutocomplete,
  },
  data: () => ({
    dialogUpdateBases: false,
    contactDialog: false,
    base: {contact: {}},
    dateWithTime: null,
    logo: null,
    fileName: null,
    clientOptions: [],
    requestTypeOptions: ['Création', 'Archivage'],
    databaseNatureOptions: ['Locale', 'Globale'],
    usageOptions: ['Usage Interne', 'Préproduction', 'Production'],
    databaseTypeOptions: [
      'Standard',
      'Interimaire',
      'Evénementiel',
      'Centre de formation',
    ],
    topsOptions:[
      'Profile',
      'Planning',
      'Dimensionnement'
    ],
    sourceDatabaseOptions: [],
    loader: false,
    clientSearch: '',
    clientOptionsFiltered: [],
    sourceDatabaseSearch: '',
    sourceDatabaseOptionsFiltered: [],
    requestedURLsRule: (v) => {
      if (!v) return 'Champ requis';
  
      // Vérifie si l'URL contient des caractères non autorisés
      if (!/^[a-zA-Z0-9-_.]+$/.test(v)) {
        return "L'URL ne peut contenir que des lettres, des chiffres, des tirets (-), des underscores (_) et des points (.)";
      }
      
      // Vérifie si l'URL commence ou se termine par un tiret ou un point
      if (/^[-.]|[-.]$/.test(v)) {
        return "L'URL ne peut pas commencer ou se terminer par un tiret (-) ou un point (.)";
      }
      
      // Vérifie si l'URL contient des termes interdits
      const forbiddenTerms = ['http', 'https', 'www'];
      for (let term of forbiddenTerms) {
        if (v.toLowerCase().includes(term)) {
          return `L'URL ne peut pas contenir le terme "${term}"`;
        }
      }
      
      // Vérifie le format général de l'URL (doit contenir au moins un point)
      if (!/^([a-zA-Z0-9-]+\.)+[a-zA-Z0-9-]+$/.test(v)) {
        return "L'URL doit être au format valide (ex: example.com)";
      }
      return true;
    },
    timezones:[],
    allData: false
  }),
  watch: {
    dateWithTime(newValue) {
      if (newValue) {
        this.base.requestDate = newValue.toISOString().split('T')[0];
      }
    },
    // afficher les données de la bonne base
    baseId() {
      this.loadBasesData(this.baseId);
    },
  },
  computed: {
    ...mapState(['clientList', 'baseList']),
    baseId() {
      return this.$store.state.selectedClientId;
    },
    isFormValid() {
      return (
        this.requestedURLsRule(this.base.requestedURLs) === true 
        // !!this.base.requestedURLs &&
        // !/\s/.test(this.base.requestedURLs)
      )
    },
  },
  filters: {
    formatDate(date) {
      if (date !== null && date !== undefined) {
        return moment(date).format('DD/MM/YYYY');
      }
    },
  },
  methods: {
    ...mapMutations(['setBaseList']),
    // récupérer les données du client
    async loadBasesData(baseId) {
      this.base = await BasesService.getBaseById(baseId); 
      // si base.contact est null, on le crée
      if (!this.base.contact) {
        this.base.contact = {};
      }
      // gestion client
      if (this.base.client) {
        this.clientOptions.push({
          name: this.base.client
        });
      }
    },
    toggleAllData() {
      if (this.allData) {
        this.base.copyUser = true;
        this.base.copyHr = true;
        this.base.copySdp = true;
        this.base.copySdc = true;
        this.base.copyPlanning = true;
      } else {
        this.base.copyUser = false;
        this.base.copyHr = false;
        this.base.copySdp = false;
        this.base.copySdc = false;
        this.base.copyPlanning = false;
      }
    },
    closeUpdateBasesModal() {
      // this.dialogUpdateBases = false;
      this.$emit('close-modal');
    },
    async handleLogoChange(file) {
      if (file) {
        let b64 = await Images.toBase64(file);
        this.newLogoPreview = b64;
        this.logo = file;
        this.fileName = file.name;
      }
    },
    onClientChange() {
      this.clientOptionsFiltered = this.clientOptions.filter((client) =>
        client.name.toLowerCase().includes(this.clientSearch.toLowerCase())
      );
    },
    onSourceDatabaseChange() {
      this.sourceDatabaseOptionsFiltered = this.sourceDatabaseOptions.filter((database) =>
        database.name.toLowerCase().includes(this.sourceDatabaseSearch.toLowerCase())
      );
    },
    selectSourceDatabase(database) {
      this.base.sourceDatabase = database;
      this.sourceDatabaseSearch = '';
      this.sourceDatabaseOptionsFiltered = [];
    },
    selectClient(client) {
      this.base.client = client;
      this.clientSearch = '';
      this.clientOptionsFiltered = [];
    },
    addContact() { 
      this.contactDialog = false;
    },
    async saveAndCloseModal() {
      this.loader = true;
      let form = new FormData();
      // si le logo est null, on ne l'envoie pas
      if (this.logo) {
        form.append('file', this.logo);
        form.append('fileName', this.fileName);
        const res = await BasesService.updateLogoBase(this.baseId, form);
        this.base.logo = res.data;
      }
      form.append('base', this.base.requestedURLs);
      form.append('baseId', this.baseId);

      const res = await BasesService.updateBase(this.baseId, this.base);
      if (res.data.message == 'Base déjà existante') {
        this.$nError('La base existe déjà');
        this.loader = false;
        return;
      }
      const bases = await BasesService.getBasesList();
      this.$store.commit('setBaseList', bases);
      this.loader = false;
      this.closeUpdateBasesModal();
      this.$nInfo('La base a été modifiée avec succès');
      this.logo = null;
    },
  },
  async mounted() {
    this.loader = true;
    this.loadBasesData(this.baseId);
    this.loader = false;
    for (const client of this.clientList) {
      this.clientOptions.push({
        name: client.name,
        id: client._id,
      });
    }
    for (const base of this.baseList.data) {
      this.sourceDatabaseOptions.push({
        name: base.requestedURLs,
        id: base._id,
      });
    }
    this.timezones = momentTimeZones.tz.names();
  },
};
</script>
<style scoped>
.form-container {
  gap: 1rem;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}
</style>
